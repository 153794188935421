import comparison from "../img/benefits-comparison.svg";
import registration from "../img/benefits-registration.svg";
import secure from "../img/benefits-secure.svg";
import options from "../img/benefits-options.svg";
import ask from "../img/benefits-ask.svg";
import search from "../img/benefits-search.svg";

// We genuinely care about you and want to make sure you get the policy that is perfect for your situation

const benefitsSliderData = [
  {
    icon: options,
    title: "100's of Coverage Options",
    description:
      "We have a lot of affordable health insurance plan options that fit your specific needs and budget."
  },
  {
    icon: search,
    title: "Guided Search Experience",
    description:
      "Simply enter some basic personal information on the quote page and we will show you side-by-side plans and pricing of all of your available policies."
  },
  {
    icon: registration,
    title: "Personalized Recommendation",
    description:
      "We provide tailored recommendations based on which doctors you want to see, out of pocket expenses, and overall cost. Call us directly to speak with a US based agent licensed in your state."
  },
  {
    icon: comparison,
    title: "Compare Our Plans",
    description:
      "Simply enter some basic personal information on the quote page and we will show you side-by-side plans and pricing of all of your available policies."
  },
  {
    icon: ask,
    title: "You May Ask Us Anything",
    description:
      "Our licensed, friendly US based healthcare professionals can answer every question you have, and are happy to guide your through the entire process. We ensure complete satisfaction."
  },
  {
    icon: secure,
    title: "100% Safe and Secure",
    description:
      "Your privacy and safety is our primary mission. We will never share your information with outside entities. We adhere to all HIPPA compliance guidelines. Our SSL certificate protects against cyber attacks and bots."
  }
];

export default benefitsSliderData;
