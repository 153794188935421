export default [
  {
    name: "Ricky",
    quote:
      "I have always been frustrated with the numerous phone calls it takes to find health insurance, but after speaking with an Healthnx liscenced agent, I finally got my first ever insurance plan. I just love how they provided a one call resolution!"
  },
  {
    name: "Jade",
    quote:
      "Got my family a new insurance plan at Healthnx! Finally a company that's straightforward. The agent I talked to was very relatable, she helped me in every step of the way. I'm very happy to have found Healthnx."
  },
  {
    name: "Katie",
    quote:
      "Healthnx saved me so much time. It is my first time trying to get an insurance plan for the whole family and everyone was covered in just under 24 hours. They were also so welcoming and made me feel like I have nothing to worry about."
  }
];
