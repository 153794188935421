import React from "react";
import { motion } from "framer-motion";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import Slider from "react-slick";

import "./styles/benefits-slider.scss";
import testimonialsData from "../default-data/testsimonialsData";

const LeftArrow = (props) => {
  const { onClick } = props;
  return (
    <motion.div whileTap={{ scale: 0.9 }} className="benefits-slider__left" onClick={onClick}>
      <LeftCircleOutlined />
    </motion.div>
  );
};

const RightArrow = (props) => {
  const { onClick } = props;
  return (
    <motion.div
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      className="benefits-slider__right"
      onClick={onClick}
    >
      <RightCircleOutlined />
    </motion.div>
  );
};

const BenefitsSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    fade: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />
  };

  const renderBenefits = () => {
    return testimonialsData.map((benefit, index) => (
      <div className="testimonials-slider__content has-text-centered-mobile" key={index}>
        <div>
          <p className="mb-3 is-size-5 line-height-4">"{benefit.quote}"</p>
          <h3 className="is-size-4 has-text-weight-semibold is-italic">-- {benefit.name}</h3>
        </div>
      </div>
    ));
  };

  return (
    <Slider className="testimonials-slider" {...settings}>
      {renderBenefits()}
    </Slider>
  );
};

export default BenefitsSlider;
