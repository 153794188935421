import React, { useEffect } from "react";
import { Button, Form, Input, Divider, Row, Col } from "antd";
import { PhoneOutlined } from "@ant-design/icons";
import Layout from "../components/Layout";
import Slider from "../components/Slider";
import { navigate } from "gatsby";

import heroImage from "../img/healthnx-hero.jpg";
import landingAffordable from "../img/healthnx-mission-one.jpg";
import landingPersonalized from "../img/healthnx-mission-two.jpg";
import landingEase from "../img/healthnx-mission-three.jpg";

import * as analytics from "../helpers/analytics";
import fbTrack from "../helpers/pixel";

import "./styles/landing-page.scss";
import benefitsSliderData from "../default-data/benefitsSliderData";

const SERVICE_LINE = process.env.INSURITA_SERVICE_LINE;

const LandingPage = () => {
  const [form] = Form.useForm();

  const handleCall = () => {
    window.open(`tel:${SERVICE_LINE}`, "_self");
    fbTrack("track", "Call Clicked");
    analytics.clickCallButton();
  };

  const validateZipCode = (zip) => {
    var zipCodePattern = /^\d{5}$|^\d{5}-\d{4}$/;
    return zipCodePattern.test(zip);
  };

  const onZipSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        if (validateZipCode(values.zip)) {
          fbTrack("track", "Landing Form", { zip: values.zip });
          navigate("/your-health-plan", {
            state: {
              zip: values.zip
            }
          });
        } else {
          form.setFields([
            {
              name: "zip",
              errors: ["This zip doesn't exist!"]
            }
          ]);
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const renderBenefits = () => {
    return benefitsSliderData.map((benefit, index) => (
      <div
        className="columns benefits-slider__content has-text-centered-mobile"
        key={benefit.title}
      >
        <div className="column">
          <img className="benefits-slider__content__icon" src={benefit.icon} alt={benefit.title} />
        </div>
        <div className="column">
          <h3 className="mb-3 is-size-5 has-text-weight-semibold">{benefit.title}</h3>
          <p className="line-height-4">{benefit.description}</p>
        </div>
      </div>
    ));
  };

  return (
    <Layout>
      <div className="landing">
        <section className="landing__hero--section">
          <div className="landing__hero wrapper-xl">
            <div className="landing__hero__intro">
              <h1 className="landing__hero__intro__title has-text-weight-bold">
                The Best Health Plan For You and Your Family
              </h1>
              <p className="landing__hero__intro__description mb-5">
                In just a few steps, we’ll bring you health plans that matches your needs. Shopping
                for a health plan has never been easier. Let us help you by entering your zip code
                to start.
              </p>
            </div>

            <div className="landing__hero__form-container">
              <div className="landing__hero__form-container__wrapper">
                <Form form={form} layout="vertical" size="large" hideRequiredMark>
                  <div className="landing__hero__form-container__wrapper__form has-text-left">
                    <Form.Item
                      name="zip"
                      rules={[
                        {
                          required: true,
                          message: " "
                        }
                      ]}
                    >
                      <Input
                        placeholder="Enter zipcode"
                        style={{ width: "100%", borderRadius: "4px 0 0 4px" }}
                        type="number"
                        pattern="\d*"
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        onClick={onZipSubmit}
                        className="button is-primary"
                        type="primary"
                        size="large"
                        block
                        style={{ borderRadius: "0 4px 4px 0" }}
                      >
                        LET'S GO
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </div>
              <div className="landing__hero__form-container__image"></div>
            </div>
          </div>
        </section>

        <div className="wrapper-xl landing__divider">
          <Divider />
        </div>

        <section className="landing__first--section section pt-0">
          <div className="container landing__wrapper landing__first">
            <h4 className="landing__subtitle has-text-centered">ABOUT US</h4>
            <h2 className="landing__title has-text-centered mb-6 has-text-weight-bold">
              Why Choose Us?
            </h2>
            <div className="landing__first__content">
              <div className="landing__first__content__image" />
              <div className="has-text-centered-mobile">
                <p className="mb-5 line-height-4">
                  Our friendly and licensed health insurance professionals are all US based and here
                  to help you find the perfect plan for your needs and budget. Health insurance can
                  be complicated but we are here to make it easy. We will help you compare the
                  different options side by side and easy for you to understand. We enroll 1000’s of
                  individuals every year and are the accredited health insurance enrollment leaders.
                </p>
                <Button
                  className="button is-primary is-outlined"
                  type="primary"
                  size="large"
                  onClick={handleCall}
                  block
                >
                  <PhoneOutlined />
                  CALL {SERVICE_LINE}
                </Button>
              </div>
            </div>
          </div>
        </section>

        <div className="wrapper-xl landing__divider">
          <Divider />
        </div>

        <section className="landing__first--section section pt-0">
          <div className="container landing__wrapper landing__first">
            <div>
              <h4 className="landing__subtitle has-text-centered">OUR MISSION</h4>
              <h2 className="landing__title has-text-centered mb-2 has-text-weight-bold">
                We Prioritize Your Needs
              </h2>
              <p className="mb-6 line-height-4 has-text-centered">
                Here at Healthnx, we are the one stop shop to discovering all your insurance related
                needs. Allow us to do the work for you. You will be surprised on how simple we make
                it to get your new health insurance plan.
              </p>
            </div>
            <div className="landing__first__qualities">
              <div className="landing__first__qualities__column">
                <img src={landingAffordable} alt="healthnx personalized health insurance" />
                <h3 className="mt-4 is-size-5 has-text-weight-semibold">Personalized</h3>
              </div>
              <div className="landing__first__qualities__column">
                <img src={landingPersonalized} alt="healthnx affordable health insurance" />
                <h3 className="mt-4 is-size-5 has-text-weight-semibold">Affordable</h3>
              </div>
              <div className="landing__first__qualities__column">
                <img src={landingEase} alt="healthnx ease and fast health insurance" />
                <h3 className="mt-4 is-size-5 has-text-weight-semibold">Ease</h3>
              </div>
            </div>
          </div>
        </section>

        <div className="wrapper-xl landing__divider">
          <Divider />
        </div>

        <section className="landing__second section pt-0">
          <div
            id="landing-second-section"
            className="landing__wrapper container has-text-centered-mobile"
          >
            <h4 className="landing__subtitle has-text-centered">ABOUT US</h4>
            <h2 className="landing__title has-text-centered mb-6 has-text-weight-bold">
              Benefits of the Right Health Plan
            </h2>
            <div className="landing__second__benefits pt-4">{renderBenefits()}</div>
          </div>
        </section>

        <div className="wrapper-xl landing__divider">
          <Divider />
        </div>

        <section className="landing__second section pt-0">
          <div
            id="landing-second-section"
            className="landing__wrapper container has-text-centered-mobile"
          >
            <h4 className="landing__subtitle has-text-centered">OUR FAMILY</h4>
            <h2 className="landing__title has-text-centered mb-6 has-text-weight-bold">
              People We Have Helped
            </h2>
            <Slider />
          </div>
        </section>
        <section className="landing__last wrapper-xl">
          <div className="landing__last__content">
            <h2 className="landing__last__content__title">Ready to start your journey?</h2>
            <p className="landing__hero__intro__description mb-5">
              Shopping for a health plan has never been easier. Let us help you by entering your zip
              code to start.
            </p>
            <div>
              <Form form={form} layout="vertical" size="large" hideRequiredMark>
                <div className="landing__last__form has-text-left">
                  <Form.Item
                    name="zip"
                    rules={[
                      {
                        required: true,
                        message: " "
                      }
                    ]}
                  >
                    <Input
                      placeholder="Zipcode"
                      style={{ width: "100%", borderRadius: "4px 0 0 4px" }}
                      type="number"
                      pattern="\d*"
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      onClick={onZipSubmit}
                      className="button is-primary"
                      type="primary"
                      size="large"
                      block
                      style={{ borderRadius: "0 4px 4px 0" }}
                    >
                      LET'S GO
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </div>
        </section>
        {/* <section className="landing__second">
          <div
            id="landing-second-section"
            className="landing__wrapper container has-text-centered-mobile"
          >
            <h4 className="landing__subtitle has-text-centered">ABOUT US</h4>
            <h2 className="landing__title has-text-centered mb-6 has-text-weight-bold">
              Benefits of a Health Plan
            </h2>
            <Slider />
          </div>
        </section> */}
      </div>
    </Layout>
  );
};

export default LandingPage;
